/**
 * 事業部
 */
import { isBlank } from '@/utilities/typing'

class Department {
    department_id;
    department_name;
    is_new_business;
    use_proposal_price;

    constructor(properties) {
        if (isBlank(properties)) {
            properties = {};
        }

        this.department_id = properties.department_id;
        this.department_name = properties.department_name;
        this.is_new_business = properties.is_new_business ?? 0;
        this.use_proposal_price = properties.use_proposal_price ?? false;
    }
}

export default Department;
